.topbar-area {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #00000021;
  background-color: transparent;
}

.topbar-list {
  padding: 0;
  margin-bottom: 0
}

.topbar-list li {
  position: relative;
  list-style-type: none;
  display: inline-block;
  color: var(--navber-color);
  margin-right: 25px;
  margin-left: 25px;
  font-size: 14px;
  cursor: pointer;
}


/* .topbar-list li::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -15px;
  width: 1px;
  height: 15px;
  background-color: #dbdbdb78;
} */

.topbar-list li:last-child::before {
  display: none
}

.topbar-list li:last-child {
  margin-right: 0
}

.topbar-list li a {
  color: var(--navber-color);
}

.topbar-list li a:hover {
  color: var(--main-color);
}

.topbar-list li i {
  color: var(--navber-color);
  padding-right: 7px;
}

.topbar-list-right {
  text-align: right;
}

.topbar-list-right li {
  position: relative;
  display: inline-flex;
  margin-right: 10px;
  font-size: 16px;
}

.topbar-list-right li:last-child {
  margin-right: 0px;
}

.topbar-list-right li a {
  color: var(--navber-color);
}

.topbar-list-right li a:hover {
  color: var(--main-color);
}

.main-responsive-nav {
  display: none;
}

.main_header_arae {
  width: 100%;
  z-index: 9999;
  background: #fff;
}

.main-navbar {
  padding: 12px 0;
  position: relative
}

.main-navbar::before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 385px;
  height: 100%;
  background-color: var(--optional-color)
}

.main-navbar .navbar {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 0
}

.main-navbar .navbar .navbar-brand {
  padding: 0 0px 0 0;
  margin: 0;
}

.main-navbar .navbar ul {
  /* padding-left: 115px; */
  list-style-type: none;
  margin-bottom: 0
}

.nav-item .dropdown-menu.show {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.nav-item .dropdown-menu .nav-item {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  justify-content: space-between;
}

.main-navbar .navbar .navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
}

.main-navbar .navbar .navbar-nav .nav-item a {
  color: #424155;
  font-size: 16px;
  font-weight: 400;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0px;
  padding-bottom: 5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-family: 'Poppins', sans-serif;
}

.main-navbar .navbar .navbar-nav .nav-item a i {
  font-size: 14px;
  position: relative;
  top: 1px;
  display: inline-block;
  margin-left: 2px;
  margin-right: 0px;
}

.main-navbar .navbar .navbar-nav .nav-item a:hover {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item:last-child {
  /* margin-right: 0 */
}

.main-navbar .navbar .navbar-nav .nav-item:first-child {
  /* margin-left: 0 */
}


.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu {
  border: none;
  top: 40px;
  left: -40px;
  z-index: 99;
  opacity: 0;
  width: 200px;
  display: block;
  border-radius: 8px;
  padding: 10px 0;
  margin-top: 15px;
  position: absolute;
  visibility: hidden;
  background: #fff;
  transition: all .2s ease-in-out;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li {
  margin: 0;
  padding: 10px 20px;
}


.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a {
  padding: 0;
  display: inline-block;
  color: var(--navber-color);
  font-size: 15px;
  font-weight: 500;
  position: relative;
  padding-bottom: 5px;
  display: block;
  width: 100%;
  text-align: center;
}

.main-navbar .navbar .navbar-nav .nav-item a:before {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  -webkit-transition: width .5s cubic-bezier(.25, .8, .25, 1) 0s;
  transition: width .5s cubic-bezier(.25, .8, .25, 1) 0s;
  background: var(--main-color)
}


.main-navbar .navbar .navbar-nav .nav-item a:hover:before,
.main-navbar .navbar .navbar-nav .nav-item a:focus:before,
.main-navbar .navbar .navbar-nav .nav-item a.active:before {
  width: 100%;
  left: 0;
  right: auto
}

.main-navbar .navbar .navbar-nav .nav-item a.active {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  right: -230px;
  left: auto;
  margin-top: 15px;
  visibility: hidden
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: var(--navber-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--main-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 230px;
  visibility: hidden
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--navber-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--navber-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -230px;
  visibility: hidden
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--navber-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 230px;
  visibility: hidden
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--navber-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -230px;
  visibility: hidden
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--navber-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #000
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 230px;
  visibility: hidden
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--navber-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--main-color)
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0
}

.main-navbar .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0
}

.main-navbar .navbar .others-options {
  margin-left: 25px
}

.main-navbar .navbar .others-options .option-item {
  margin-right: 30px;
  position: relative
}

.main-navbar .navbar .others-options .option-item::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -15px;
  width: 1px;
  height: 15px;
  background-color: #dbdbdb
}

.main-navbar .navbar .others-options .option-item:first-child {
  margin-right: 35px;
}

.main-navbar .navbar .others-options .option-item:first-child::before {
  display: none
}

.main-navbar .navbar .others-options .option-item:last-child {
  margin-right: 0
}

.main-navbar .navbar .others-options .option-item:last-child::before {
  display: none
}

.main-navbar .navbar .others-options .option-item .search-box {
  position: relative;
  top: 2px
}

.main-navbar .navbar .others-options .option-item .search-box i {
  font-size: 22px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--white-color)
}

.main-navbar .navbar .others-options .option-item .search-box i:hover {
  color: var(--main-color)
}

.main-navbar .navbar .others-options .option-item .user-icon {
  position: relative;
  top: 2px
}

.main-navbar .navbar .others-options .option-item .user-icon i {
  font-size: 22px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #fff
}

.main-navbar .navbar .others-options .option-item .user-icon i:hover {
  color: var(--main-color)
}

.main-navbar .navbar .others-options .option-item .cart-btn {
  position: relative;
  top: 2px
}

.main-navbar .navbar .others-options .option-item .cart-btn i {
  font-size: 22px;
  color: red;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #fff
}

.main-navbar .navbar .others-options .option-item .cart-btn i:hover {
  color: var(--main-color)
}

.main-navbar .navbar .others-options .option-item .cart-btn span {
  position: absolute;
  top: -5px;
  right: -2px;
  display: inline-block;
  height: 15px;
  width: 15px;
  line-height: 15px;
  background-color: var(--navber-color);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 50px
}

.main-navbar .navbar .others-options .option-item .language-option {
  padding: 0
}

.main-navbar .navbar .others-options .option-item .language-option button {
  padding: 0;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding-right: 22px
}

.main-navbar .navbar .others-options .option-item .language-option button::after {
  display: none
}

.main-navbar .navbar .others-options .option-item .language-option button::before {
  content: "\ea4e";
  display: block;
  position: absolute;
  top: 2px;
  right: 0;
  font-size: 15px;
  border-color: #fff;
  font-family: remixicon
}

.main-navbar .navbar .others-options .option-item .language-option .dropdown-menu[style] {
  -webkit-transform: translateY(30px);
  transform: translateY(30px)
}

.main-navbar .navbar .others-options .option-item .language-option .dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto
}


.main-navbar .navbar .others-options .option-item .language-option .language-dropdown-menu {
  border-radius: 5px;
  border: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, .1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, .1);
  padding: 0;
  margin-top: 10px
}

.main-navbar .navbar .others-options .option-item .language-option .language-dropdown-menu .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--paragraph-color);
  font-size: var(--font-size);
  font-weight: 500;
  padding: 10px 20px;
  border-bottom: 1px solid #eee
}

.main-navbar .navbar .others-options .option-item .language-option .language-dropdown-menu .dropdown-item:last-child {
  border-bottom: none
}

.main-navbar .navbar .others-options .option-item .language-option .language-dropdown-menu .dropdown-item:active {
  color: #fff;
  background-color: var(--main-color);
}

.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, .06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, .06);
  background: var(--white-color) !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-transition: .6s;
  transition: .6s
}

.others-option-for-responsive {
  display: none
}

.others-option-for-responsive .dot-menu {
  padding: 0 10px;
  height: 30px;
  cursor: pointer;
  z-index: 9991;
  position: absolute;
  right: 52px;
  top: -42px;
  font-weight: 400
}

.others-option-for-responsive .dot-menu .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px
}

.others-option-for-responsive .dot-menu .inner .circle {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--main-color);
}

.others-option-for-responsive .dot-menu:hover .inner .circle {
  background-color: var(--main-color)
}

.others-option-for-responsive .container {
  position: relative
}

.others-option-for-responsive .container .container {
  position: absolute;
  right: 15px;
  top: 15px;
  max-width: 205px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  z-index: 2;
  padding: 15px;
  text-align: center
}

.others-option-for-responsive .container .container.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1)
}

.others-option-for-responsive .option-inner .others-options {
  margin-left: 25px
}

.others-option-for-responsive .option-inner .others-options .option-item {
  margin-right: 30px;
  position: relative
}

.others-option-for-responsive .option-inner .others-options .option-item::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -15px;
  width: 1px;
  height: 15px;
  background-color: #dbdbdb
}

.others-option-for-responsive .option-inner .others-options .option-item:first-child {
  margin-right: 70px
}

.others-option-for-responsive .option-inner .others-options .option-item:first-child::before {
  display: none
}

.others-option-for-responsive .option-inner .others-options .option-item:last-child {
  margin-right: 0
}

.others-option-for-responsive .option-inner .others-options .option-item:last-child::before {
  display: none
}

.others-option-for-responsive .option-inner .others-options .option-item .search-box {
  position: relative;
  top: 2px
}

.others-option-for-responsive .option-inner .others-options .option-item .search-box i {
  font-size: 22px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #fff
}

.others-option-for-responsive .option-inner .others-options .option-item .search-box i:hover {
  color: #000
}

.others-option-for-responsive .option-inner .others-options .option-item .user-icon {
  position: relative;
  top: 2px
}

.others-option-for-responsive .option-inner .others-options .option-item .user-icon i {
  font-size: 22px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #fff
}

.others-option-for-responsive .option-inner .others-options .option-item .user-icon i:hover {
  color: #000
}

.others-option-for-responsive .option-inner .others-options .option-item .cart-btn {
  position: relative;
  top: 2px
}

.others-option-for-responsive .option-inner .others-options .option-item .cart-btn i {
  font-size: 22px;
  color: red;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #fff
}

.others-option-for-responsive .option-inner .others-options .option-item .cart-btn i:hover {
  color: #000
}

.others-option-for-responsive .option-inner .others-options .option-item .cart-btn span {
  position: absolute;
  top: -5px;
  right: -2px;
  display: inline-block;
  height: 15px;
  width: 15px;
  line-height: 15px;
  background-color: #000;
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 50px
}

.others-option-for-responsive .option-inner .others-options .option-item .language-option {
  padding: 0
}

.others-option-for-responsive .option-inner .others-options .option-item .language-option button {
  padding: 0;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: var(--font-size);
  font-weight: 500;
  padding-right: 22px
}

.others-option-for-responsive .option-inner .others-options .option-item .language-option button::after {
  display: none
}

.others-option-for-responsive .option-inner .others-options .option-item .language-option button::before {
  content: "\ea4e";
  display: block;
  position: absolute;
  top: 2px;
  right: 0;
  font-size: 15px;
  border-color: #fff;
  font-family: remixicon
}

.others-option-for-responsive .option-inner .others-options .option-item .language-option .dropdown-menu[style] {
  -webkit-transform: translateY(30px);
  transform: translateY(30px)
}

.others-option-for-responsive .option-inner .others-options .option-item .language-option .dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto
}

.others-option-for-responsive .option-inner .others-options .option-item .language-option .language-dropdown-menu {
  border-radius: 5px;
  border: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, .1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, .1);
  padding: 0;
  margin-top: 10px
}

.others-option-for-responsive .option-inner .others-options .option-item .language-option .language-dropdown-menu .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--paragraph-color);
  font-size: var(--font-size);
  font-weight: 500;
  padding: 10px 20px;
  border-bottom: 1px solid #eee
}

.others-option-for-responsive .option-inner .others-options .option-item .language-option .language-dropdown-menu .dropdown-item:last-child {
  border-bottom: none
}

.others-option-for-responsive .option-inner .others-options .option-item .language-option .language-dropdown-menu .dropdown-item:active {
  color: #fff;
  background-color: #000
}

.search-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  overflow: hidden
}

.search-overlay .search-overlay-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  -webkit-transform: translateX(100%);
  transform: translateX(100%)
}

.search-overlay .search-overlay-layer:nth-child(1) {
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  -webkit-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s
}

.search-overlay .search-overlay-layer:nth-child(2) {
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  -webkit-transition: all .3s ease-in-out .3s;
  transition: all .3s ease-in-out .3s
}

.search-overlay .search-overlay-layer:nth-child(3) {
  left: 0;
  background-color: rgba(0, 0, 0, .7);
  -webkit-transition: all .9s ease-in-out .6s;
  transition: all .9s ease-in-out .6s
}

.search-overlay .search-overlay-close {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 50px;
  z-index: 2;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  -webkit-transition: all .9s ease-in-out 1.5s;
  transition: all .9s ease-in-out 1.5s;
  opacity: 0;
  visibility: hidden
}

.search-overlay .search-overlay-close .search-overlay-close-line {
  width: 100%;
  height: 3px;
  float: left;
  margin-bottom: 5px;
  background-color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease
}

.search-overlay .search-overlay-close .search-overlay-close-line:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.search-overlay .search-overlay-close .search-overlay-close-line:nth-child(2) {
  margin-top: -7px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.search-overlay .search-overlay-close:hover .search-overlay-close-line {
  background: #fff;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.search-overlay .search-overlay-form {
  -webkit-transition: all .9s ease-in-out 1.4s;
  transition: all .9s ease-in-out 1.4s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  max-width: 500px;
  width: 500px
}

.search-overlay .search-overlay-form form {
  position: relative
}

.search-overlay .search-overlay-form form .input-search {
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 30px;
  color: var(--paragraph-color);
  padding: 0 0 0 25px;
  font-weight: 500;
  outline: 0
}

.search-overlay .search-overlay-form form .input-search::-webkit-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--paragraph-color)
}

.search-overlay .search-overlay-form form .input-search:-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--paragraph-color)
}

.search-overlay .search-overlay-form form .input-search::-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--paragraph-color)
}

.search-overlay .search-overlay-form form .input-search::placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--paragraph-color)
}

.search-overlay .search-overlay-form form .input-search:focus::-webkit-input-placeholder {
  color: transparent
}

.search-overlay .search-overlay-form form .input-search:focus:-ms-input-placeholder {
  color: transparent
}

.search-overlay .search-overlay-form form .input-search:focus::-ms-input-placeholder {
  color: transparent
}

.search-overlay .search-overlay-form form .input-search:focus::placeholder {
  color: transparent
}

.search-overlay .search-overlay-form form button {
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 50px;
  color: #fff;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: none;
  font-size: 20px;
  line-height: 50px
}

.search-overlay .search-overlay-form form button:hover {
  background-color: var(--main-color);
  color: #fff
}

.search-overlay.search-overlay-active.search-overlay {
  opacity: 1;
  visibility: visible
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-layer {
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-close {
  opacity: 1;
  visibility: visible
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-form {
  opacity: 1;
  visibility: visible
}

@media only screen and (max-width:1199px) {
  .main-responsive-nav {
    display: block
  }

  .main-responsive-nav .main-responsive-menu {
    position: relative
  }

  .main-responsive-nav .main-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px
  }

  .main-responsive-nav .main-responsive-menu.mean-container .mean-nav ul li a.active {
    color: #000
  }

  .main-responsive-nav .main-responsive-menu.mean-container .mean-nav ul li a i {
    display: none
  }

  .main-responsive-nav .main-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 14px
  }

  .main-responsive-nav .main-responsive-menu.mean-container .others-options {
    display: none !important
  }

  .main-responsive-nav .main-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 300px;
    -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
    background-color: #fff
  }

  .main-responsive-nav .mean-container a.meanmenu-reveal {
    padding: 4px 0 0;
    color: var(--main-color)
  }

  .main-responsive-nav .mean-container a.meanmenu-reveal span {
    display: block;
    background: var(--main-color);
    height: 2px;
    margin-top: -10px;
    border-radius: 5px;
    position: relative;
    top: 22px;
  }

  .main-responsive-nav .mean-container .mean-bar {
    background-color: unset;
    border-bottom: none
  }

  .main-responsive-nav .mean-container .mean-nav {
    margin-top: 50px
  }

  .main-responsive-nav .logo {
    position: relative;
    z-index: 999;
    width: 50%
  }

  .navbar-area {
    background-color: #fff;
    padding-top: 15px;
    padding-bottom: 15px
  }

  .topbar-area {
    background-color: #fff;
  }


  .main-navbar {
    display: none
  }

  .others-option-for-responsive {
    display: block
  }
}

@media only screen and (max-width:767px) {
  .search-overlay .search-overlay-form {
    max-width: 280px;
    width: 280px
  }

  .search-overlay .search-overlay-close {
    top: 20px;
    right: 20px
  }

  .others-option-for-responsive .container .container {
    max-width: 190px
  }

  .others-option-for-responsive .option-inner .others-options {
    margin-left: 0
  }

  .others-option-for-responsive .option-inner .others-options.d-flex {
    display: inline-block !important
  }

  .others-option-for-responsive .option-inner .others-options .option-item {
    margin-right: 0;
    margin-bottom: 20px
  }

  .others-option-for-responsive .option-inner .others-options .option-item::before {
    display: none
  }

  .others-option-for-responsive .option-inner .others-options .option-item:first-child {
    margin-right: 0
  }

  .others-option-for-responsive .option-inner .others-options .option-item:last-child {
    margin-bottom: 0
  }

  .others-option-for-responsive .option-inner .others-options .option-item .search-box i {
    color: var(--paragraph-color)
  }

  .others-option-for-responsive .option-inner .others-options .option-item .user-icon i {
    color: var(--paragraph-color)
  }

  .others-option-for-responsive .option-inner .others-options .option-item .cart-btn i {
    color: var(--paragraph-color)
  }

  .others-option-for-responsive .option-inner .others-options .option-item .language-option {
    text-align: left
  }

  .others-option-for-responsive .option-inner .others-options .option-item .language-option button {
    color: var(--paragraph-color)
  }

  .topbar-list {
    text-align: center
  }

  .topbar-list li {
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .topbar-list li svg {
    margin: 0 5px;
  }

  .topbar-list li:last-child {
    /* margin-bottom: 0 */
  }

  .topbar-others-options {
    text-align: center;
    margin-top: 10px
  }
}

@media only screen and (min-width:325px) {
  .others-option-for-responsive .dot-menu {
    top: -46px;
    right: 80px;
  }
}
.topbar-list li svg {
  margin: 0 5px;
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .others-option-for-responsive .container .container {
    max-width: 190px
  }

  .others-option-for-responsive .option-inner .others-options {
    margin-left: 0
  }

  .others-option-for-responsive .option-inner .others-options.d-flex {
    display: inline-block !important
  }

  .others-option-for-responsive .option-inner .others-options .option-item {
    margin-right: 0;
    margin-bottom: 20px
  }

  .others-option-for-responsive .option-inner .others-options .option-item::before {
    display: none
  }

  .others-option-for-responsive .option-inner .others-options .option-item:first-child {
    margin-right: 0
  }

  .others-option-for-responsive .option-inner .others-options .option-item:last-child {
    margin-bottom: 0
  }

  .others-option-for-responsive .option-inner .others-options .option-item .search-box i {
    color: var(--paragraph-color)
  }

  .others-option-for-responsive .option-inner .others-options .option-item .user-icon i {
    color: var(--paragraph-color)
  }

  .others-option-for-responsive .option-inner .others-options .option-item .cart-btn i {
    color: var(--paragraph-color)
  }

  .others-option-for-responsive .option-inner .others-options .option-item .language-option {
    text-align: left
  }

  .others-option-for-responsive .option-inner .others-options .option-item .language-option button {
    color: var(--paragraph-color)
  }

  .mean-container .mean-nav ul li a.mean-expand {
    right: 20px
  }

  .topbar-list li {
    margin-right: 25px;
    font-size: 14px;
  }

}

@media only screen and (min-width:992px) and (max-width:1199px) {
  .others-option-for-responsive .container .container {
    max-width: 190px
  }

  .others-option-for-responsive .option-inner .others-options {
    margin-left: 0
  }

  .others-option-for-responsive .option-inner .others-options.d-flex {
    display: inline-block !important
  }

  .others-option-for-responsive .option-inner .others-options .option-item {
    margin-right: 0;
    margin-bottom: 20px
  }

  .others-option-for-responsive .option-inner .others-options .option-item::before {
    display: none
  }

  .others-option-for-responsive .option-inner .others-options .option-item:first-child {
    margin-right: 0
  }

  .others-option-for-responsive .option-inner .others-options .option-item:last-child {
    margin-bottom: 0
  }

  .others-option-for-responsive .option-inner .others-options .option-item .search-box i {
    color: var(--paragraph-color)
  }

  .others-option-for-responsive .option-inner .others-options .option-item .user-icon i {
    color: var(--paragraph-color)
  }

  .others-option-for-responsive .option-inner .others-options .option-item .cart-btn i {
    color: var(--paragraph-color)
  }

  .others-option-for-responsive .option-inner .others-options .option-item .language-option {
    text-align: left
  }

  .others-option-for-responsive .option-inner .others-options .option-item .language-option button {
    color: var(--paragraph-color)
  }

  .mean-container .mean-bar {
    padding: 0 !important;
  }

  .mean-container .mean-nav ul li a.mean-expand {
    right: 25px
  }
}

@media only screen and (min-width:1200px) and (max-width:1399px) {
  .main-navbar .navbar .navbar-nav .nav-item {
    margin-left: 10px;
    margin-right: 20px
  }

  .main-navbar .navbar .others-options .option-item:first-child {
    margin-right: 30px
  }
}

@media only screen and (min-width:1550px) {
  .main-navbar::before {
    width: 575px
  }
}

.mean-menu {
  justify-content: space-between;
}


.scrollTop {
  position: fixed;
  bottom: -20%;
  right: 40px;
  width: 55px;
  height: 55px;
  background-color: #272f59;
  border-radius: 50%;
  background-size: 40px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 1000;
  opacity: 0;
  transition: .3s all;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .icon-btnUp {
  width: 40px;
  height: 40px;
  object-fit: contain;
} */

@media screen and (max-width: 350px) {
  .scrollTop {
    right: 25px;
  }
}

.scrollTop.active {
  visibility: visible;
  opacity: 1;
  bottom: 30px;
  right: 20px;
}

.scrollTop:hover {
  bottom: 45px;
}

.scrollTop svg {
  scale: 3.4;
  color: var(--main-color);
  margin: 0;
}

@media screen and (max-width: 1200px) {
  .main-responsive-menu {
    position: relative;
  }

  .navbar-nav {
    position: absolute;
    bottom: -345px;
    left: 0;
    z-index: 3;
    background: #fafcff;
    border: 1px solid #dae3f2;
    width: 100%;
    border-radius: 10px;
  }

  .navbar-nav li {
    padding: 7px 25px;
    position: relative;
  }

  .navbar-nav li a {
    font-size: 17px;
    padding: 4px 10px;
  }
}


.nav-item::before {
  content: "";
  position: absolute;
  width: 0;
  height: 4px;
  left: 0;
  top: -3px;
  transition: .4s all;
  background-color: var(--main-color);
  border-radius: 20px;
}

@media screen and (max-width: 1200px) {
  .nav-item::before {
    top: 0px;
    height: 3px;
  }
}

.nav-item:hover::before {
  width: 100%;
}