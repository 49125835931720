.contact {
  width: 100%;
  /* height: 90vh; */
  display: flex;
  padding: 80px 0;
}

.contact .leftSide {
  height: 100%;
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .contact {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
}

.contact .rightSide {
  height: 100%;
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .rightSide h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 10;
  font-size: 50px;
  margin-left: 30px;
}

.contact form {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 30px;
}

.contact form input {
  height: 46px;
  width: 80%;
  border: 1px solid #c4c4c4;
  padding: 12px 12px;
  border-radius: 6px;
  color: #0c0c0c;
}


.contact form textarea {
  margin-top: 15px;
  height: 70px;
  width: 80%;
  border: 1px solid #c4c4c4;
  padding: 12px 12px;
  border-radius: 6px;
  color: #0c0c0c;
}

.contact input::placeholder,
.contact textarea::placeholder {
  color: #333;
}


@media screen and (max-width: 768px) {
  .contact form input {
    width: 100%;
  }

  .contact form textarea {
    width: 100%;
  }

}

.contact form input::-webkit-input-placeholder {
  /* Edge */
  color: #333;
}

.contact form input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #333;
}

.contact form input::placeholder {
  color: #333;
}

.contact form input:focus,
.contact form textarea:focus {
  outline: none;
  background: #fff;
}

.contact form label {
  margin-top: 15px;
  color: #1f1f1f;
}

.contact .submit-btn {
  margin-top: 40px;
  width: 150px;
  padding: 10px 15px;
  border: none;
  background-color: #0d6efd;
  color: whitesmoke;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  transition: .3s all;
}

/* .section-title.v1 h3 {
  border-width: 1px 0;
  border-style: solid;
  padding: 4px 15px 0;
  font-size: 32px;
}

.contact-details .boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.contact-details .box {
  background-color: rgba(127,127,127,0.075);
  min-height: 206px;
  padding: 60px 30px 21px;
  position: relative;
  border: 1px solid rgba(127,127,127,0.25);
  flex-grow: 1;
}

.contact-details .cols-3 .box {
  width: 280px;
}


.contact-details .box::before {
  content: "";
  border: 1px dashed rgba(127,127,127, 0.25);
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
}
.contact-details .box h3 {
  margin-bottom: 5px;
}
.contact-details ul, .contact-details .content {
  text-align: center;
  margin: 0;
  position: relative;
  z-index: 1;
  opacity: 0.7;
  list-style: none;
}

.elementor-widget-wrap>.elementor-element {
  width: 100%;
}

.contact-details span.fa, .contact-details .icon {
  width: 70px;
  font-size: 15px;
  height: 45px;
  line-height: 40px;
  position: absolute;
  top: -3px;
  left: 50%;
  margin-left: -35px;
  border-radius: 0% 0% 50% 50%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  z-index: 1;
  background-color: var(--content-ac);
  color: var(--content-bg);
} */