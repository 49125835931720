/* --Footer Area-- */
#footer_area {
  padding: 5px 0 0px 0;
  background: #F4F7FC;
}

.footer_area_about {
  /* padding-left: 100px; */
}

.logoFooter {
  min-width: 150px;
  transition: .3s all;
}

.logoFooter:hover {
  transform: scale(1.05);
}
@media screen and (max-width: 768px) {
  .footer_area_about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;
  }

  .logoFooter {
    min-width: 80px !important;
  }
}


.footer_area_about p {
  padding-top: 20px;
  padding-bottom: 13px;
  font-size: 18px;
}

.footer_area_about h6 {
  padding-top: 5px;
  font-size: 16px;
  color: var(--paragraph-color);
  line-height: 27px;
}

.footer_area_about h6 strong {
  color: var(--black-color);
}

.footer_area_about h6 a {
  color: var(--paragraph-color);
}

.footer_area_about h6 a:hover {
  color: var(--main-color);
}

.footer_navitem_ara h3 {
  padding-bottom: 25px;
}

.nav_item_footer h6 {
  font-size: 18px;
}

.nav_item_footer ul li {
  padding-bottom: 20px;
}

.nav_item_footer ul li:last-child {
  padding-bottom: 0px;
}

.nav_item_footer ul li a {
  color: var(--black-color);
}

.nav_item_footer ul li a:hover {
  color: var(--main-color);
}

.footer_twitter_area p {
  padding: 10px 0;
}

.footer_twitter_area h6 {
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px;
}

@media screen and (max-width: 768px) {
  .copyright_right ul {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
}