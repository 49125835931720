/*
/*
@File: AndTour - Travel Agency HTML Template

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

** Common Style
** Home Page
** About Page
** Causes Page
** Cause Details Page
** Contact Page
** Event Page
** Event Details Page
** Faqs Page
** Gallery Grid Page
** Gallery Slider Page
** Login Page
** Registration Page
** Make Donation Page
** News Page
** News Details Page
** Privacy policy Page
** Terms Service Page
** Testimonials  Page
** volunter Page
** Preloader
** Top TO Bottom


/*================================================
Default CSS
=================================================*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,600&display=swap');

:root {
    --main-color: #25325e;
    --white-color: #ffffff;
    --black-color: #2A283E;
    --navber-color: #424155;
    --paragraph-color: #818090;
    --bg-color: #EEF4F8;
    --transition: .4s all ease-in-out;
}

html,
body {
    height: 100%;
}

body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto;
}

.main-color {
    color: var(--main-color);
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.mb-30 {
    margin-bottom: 30px;
}

a {
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
    outline: 0 !important;
    color: var(--main-color);
}

a:hover {
    text-decoration: none;
    color: var(--heading-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
    margin: 0;
}

h1 {
    font-size: 70px;
    font-weight: 400;
    line-height: 80px;
}

h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 50px;
}

h3 {
    font-size: 24px;
    font-weight: 500;
}

h4 {
    font-size: 20px;
    font-weight: 500;
}

h5 {
    font-size: 18px;
    font-weight: 400;
}

h6 {
    font-size: 14px;
    font-weight: 400;
}

p {
    font-size: 16px;
    line-height: 24px;
    color: var(--paragraph-color);
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0;
}

p:last-child {
    margin-bottom: 0;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
    padding: 0;
}

/* --Common Style-- */
section {
    position: relative;
}

.form-control {
    height: 60px;
    border: none;
    box-shadow: 0px 1px 13px 0px #0000000d;
    padding: 10px 22px;
    font-size: 16px;
    background: #e03c3314;
}

.form-control:focus {
    /* color: var(--heading-color); */
    background-color: #fff;
    border: 1px solid var(--main-color);
    outline: none;
    box-shadow: none;
}

.section_padding {
    padding: 120px 0 150px;
}

.section_padding_top {
    padding: 100px 0 0 0;
}

.section_padding_bottom {
    padding: 0 0 100px 0;
}

.bg-color {
    background: #EEF4F8;
}

.bg-theme {
    background: var(--main-color);
}

.bg-yellow {
    background: #F6A61D;
}

/* --case-boxed-- */
.case_boxed_wrapper {
    box-shadow: 8px 10px 24px rgba(0, 0, 0, 0.1);
    background: var(--white-color);
    border-radius: 12px;
    overflow: hidden;
    transition: var(--transition);
    position: relative;
    z-index: 9;
}

.case_boxed_wrapper:hover .case_boxed_img a img {
    transform: scale(1.2) rotate(3deg);
}

.projects .case_boxed_img {
    position: relative;
    overflow: hidden;
    height: 230px;
    width: 100%;
    object-fit: contain;
}

.case_boxed_img img#img-64 {
    width: 100%;
    height: 200px;
    transition: var(--transition);
}

.causes_badge {
    position: absolute;
    top: 6px;
    left: 6px;
    color: var(--white-color);
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 5px;
    opacity: .85;
}

.causes_boxed_text_ar {
    padding: 15px 20px 25px;
    height: 150px;
    overflow: hidden;
}

.causes_boxed_text {
    padding: 15px 20px 25px;
    height: 200px;
    overflow: hidden;
}

.causes_boxed_text h3 {
    font-weight: 500;
}

.causes_boxed_text h3 a {
    color: var(--black-color);
    padding-top: 15px;
    display: block;
}

.causes_boxed_text h3 a:hover {
    color: var(--main-color);
}

.causes_boxed_text p {
    padding-top: 10px;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .causes_boxed_text {    
        height: 240px;
    }
}

.causes_boxed_bottom_wrapper {
    border-top: 1px solid #c4c4c457;
    padding-top: 15px;
    margin-top: 17px;
}

.causes_boxed_bottom_wrapper .row .col-lg-6:last-child .casuses_bottom_boxed {
    border-right: none;
}

.casuses_bottom_boxed {
    display: flex;
    align-items: center;
    border-right: 1px solid #e6e6e6;
}

.casuses_bottom_content {
    padding-left: 8px;
}

.casuses_bottom_content h5 {
    font-size: 16px;
    font-weight: 500;
}

.casuses_bottom_content p {
    padding-top: 0;
    font-size: 14px;
}


.skill-bar-percent {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.skill-bar {
    width: 100%;
    height: 10px;
    position: relative;
    margin-top: 10px;
}

.skill-bar:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgb(253, 61, 12, 0.2);
}

.skill-bar-inner {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 0;
    height: 100%;
    overflow: visible;
}

.skill-bar-inner:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: var(--main-color);
}

.class-full-bar-box {
    position: relative;
}

.class-full-bar-box .h3-title {
    font-size: 15px;
    line-height: 15px;
    color: var(--black-color);
    font-weight: 600;
}

.class-full-bar-box .h3-title span {
    color: var(--paragraph-color);
    font-weight: 400;
}

.class-full-bar-percent {
    position: absolute;
    top: -10px;
    right: 0;
    font-size: 15px;
    line-height: 15px;
    font-weight: 800;
    color: #10161b;
    display: flex;
    align-items: center;
}

.class-full-bar-percent h2 {
    font-size: 17px;
    line-height: 28px;
}

.class-full-bar-percent .h3-title {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 15px;
}

.causes_pro_bar {
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
    padding: 20px 20px;
    border-radius: 10px;
    margin-top: -50px;
    z-index: 999;
    position: relative;
}

/* --Event Boxed Area-- */
.event_left_side_wrapper {
    background: #FFFFFF;
    box-shadow: -4px 6px 24px rgba(0, 0, 0, 0.08), 6px 12px 24px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.event_big_img {
    position: relative;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.event_big_img a img {
    width: 100%;
    transition: var(--transition);
}

.event_left_side_wrapper:hover .event_big_img a img {
    transform: scale(1.2) rotate(3deg);
}

.event_content_area {
    position: relative;
}

.big_content_padding {
    padding: 15px 18px;
}

.small_content_padding {
    padding: 15px 18px;
    margin-bottom: 20px;
}

.small_content_padding .event_heading_area {
    padding-top: 7px;
}

.small_content_padding .event_para {
    padding-top: 8px;
    padding-right: 90px;
}

.event_tag_area a {
    font-size: 18px;
    font-weight: 500;
}

.event_heading_area {
    padding-top: 15px;
}

.event_heading {
    padding-right: 90px;
}

.event_heading h3 {
    line-height: 34px;
}

.event_heading h3 a {
    color: var(--black-color);
}

.event_heading h3 a:hover {
    color: var(--main-color);
}

.event_date {
    position: absolute;
    width: 85px;
    height: 83px;
    text-align: center;
    right: 32px;
    top: 31px;
}

.event_date h6 {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 18px;
    color: #fff;
    transform: translate(-50%, -58%);
}

.event_date h6 span {
    display: block;
}

.event_para {
    padding-top: 18px;
    padding-right: 90px;
}

.event_boxed_bottom_wrapper {
    border-top: 1px solid #c4c4c440;
    border-bottom: 1px solid #c4c4c440;
    padding-top: 15px;
    margin-top: 17px;
    padding-bottom: 15px;
}

.event_boxed_bottom_wrapper .row .col-lg-6:last-child .event_bottom_boxed {
    border-right: none;
}

.event_bottom_boxed {
    display: flex;
    align-items: center;
    border-right: 1px solid #e6e6e6;
}

.event_bottom_content {
    padding-left: 8px;
}

.event_bottom_content h5 {
    font-size: 16px;
    font-weight: 500;
}

.event_bottom_content p {
    padding-top: 0;
    font-size: 14px;
}

.event_button {
    margin-top: 30px;
}

.event_button a {
    width: 100%;
}

/* --Heading Area-- */
.section_heading {
    text-align: center;
    margin-bottom: 50px;
}

.section_heading h3 {
    color: var(--paragraph-color);
    font-weight: 400;
    margin-bottom: -30px;
}

.section_heading h2 {
    padding-top: 20px;
    position: relative;
    font-size: 35px;
    text-align: start;
}

/* .section_heading h2:before {
    content: url('./assets/img/icon/heading.png');
} */

.section_after {
    position: relative;
}

.section_after::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 240px;
    background-color: var(--white-color);
}

.img_animation {
    overflow: hidden;
}

.img_animation img {
    transition: var(--transition);
    position: relative;
}

.img_animation:hover img {
    transform: scale(1.2);
}


/* --Button Area start-- */
.btn-check:focus+.btn,
.btn:focus {
    outline: none;
    box-shadow: none
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: none;
    overflow: hidden;
    position: relative;
    z-index: 0;
}

.btn:hover {
    color: var(--main-color);
}

.btn_theme {
    color: var(--white-color);
    background-color: var(--main-color);
    transition: var(--transition);
    box-shadow: none;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 0;
    border: none
}

.btn_theme:hover {
    background-color: var(--black-color);
    color: #d5dbdd;
}

.btn_md {
    padding: 12px 35px;
    font-size: 18px;
}

.btn_md.btn_theme {
    transition: .3s all;
}

.btn_md_orange {
    background-color: #ff8600;
    font-size: 25px;
    padding: 12px 35px !important;
    border-radius: 50px;
}

.btn_md_orange:hover {
    background-color: #ee8c1d;
    color: #fff;
}

.btn_md.btn_theme:hover {
    transform: scale(1.02);
}

@media screen and (max-width: 768px) {
    .btn_md {
        padding: 6px 15px;
        font-size: 14px;
    }

    .btn_md_orange {
        font-size: 17px !important;
        font-weight: 700;
    }

    .donate_text h2 {
        font-size: 22px !important;
        /* font-weight: 500 !important; */
        line-height: 40px !important;
    }


}

.btn_sm {
    font-size: 14px;
    padding: 5px 18px;
}

.btn_navber {
    color: var(--navber-color);
    border: 2px solid var(--main-color);
    padding: 7px 20px;
    transition: var(--transition);
    box-shadow: none;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 0;
}

.btn_navber:hover {
    background: var(--main-color);
}

.is-sticky .btn_navber {
    border: 1px solid var(--main-color);
}


/* =========================
        Home Page
=========================*/
/* --Banner-- */
#home_one_banner {
    background-image: url('./assets/img/banner/home-banner-bg.png');
    padding: 60px 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner_one_img {
    position: relative;
    text-align: right;
}

.banner_one_text h1 span {
    font-weight: 700;
}

.banner_one_text .color_big {
    position: relative;
    z-index: 2;
}

.banner_one_text .color_big::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    background: #FDC221;
    bottom: 21px;
    left: 0;
    z-index: -1;
}

.banner_one_text p {
    padding-top: 20px;
    font-size: 18px;
    line-height: 30px;
}

.banner_element img {
    position: absolute;
    box-shadow: 10px 12px 30px rgba(0, 0, 0, 0.1);
}

.element_1 {
    top: 44%;
    left: 64px;
}

.element_2 {
    top: 23%;
    right: -14%;
}

.element_3 {
    bottom: 0;
    left: 67%;
}

.shape-1 {
    -webkit-animation: left1right 5s linear 0s infinite;
    animation: left1right 5s linear 0s infinite;
}

@keyframes left1right {

    50% {
        -ms-transform: translateY(20px);
        /* IE 9 */
        -webkit-transform: translateY(20px);
        /* Chrome, Safari, Opera */
        transform: translateY(20px);
    }
}

.shape-2 {
    -webkit-animation: left2right 3s linear 0s infinite;
    animation: left2right 3s linear 0s infinite;
}

@keyframes left2right {

    50% {
        -ms-transform: translateY(20px);
        /* IE 9 */
        -webkit-transform: translateY(20px);
        /* Chrome, Safari, Opera */
        transform: translateY(20px);
    }
}

.shape-3 {
    -webkit-animation: left13right 9s linear 0s infinite;
    animation: left3right 9s linear 0s infinite;
}

@keyframes left3right {

    50% {
        -ms-transform: translateY(20px);
        /* IE 9 */
        -webkit-transform: translateY(20px);
        /* Chrome, Safari, Opera */
        transform: translateY(20px);
    }
}

/* --About Top Area-- */
.about_top_boxed {
    position: relative;
    border-radius: 20px;
    padding: 40px 30px 20px 30px;
    transition: var(--transition);
    height: 230px;
}

.about_top_boxed:hover {
    transform: translateY(10px);
}

.bg_one {
    background: #CAE4F7;
}

.bg_two {
    background: #FDF0DD;
}

.bg_three {
    background: #D2F4F4;
}

.bg_four {
    background: #E0EAFD;
}

.about_top_boxed_icon {
    text-align: center;
    margin: 0px auto 0 auto;
    margin-top: -45px;
    transition: var(--transition);
}

.about_top_boxed:hover .about_top_boxed_icon {
    transform: translateY(20px);
}

.about_top_boxed_icon img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: 0px -4px 16px rgb(0 0 0 / 6%), 4px 8px 16px rgb(0 0 0 / 10%);
    margin-top: -45px;
}

.about_top_boxed_text {
    padding-top: 20px;
}

.about_top_boxed_text h3 {
    font-size: 27px !important;
    font-weight: 600;
    padding: 6px 0;
}

.about_top_boxed_text h5 {
    font-size: 16px;
    font-weight: 600;
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
    .about_top_boxed_text {
        padding-top: 10px;
    }

    .about_top_boxed_icon {
        margin-top: -55px;
    }

    .about_top_boxed_text h3 {
        font-size: 24px !important;
        font-weight: 500;
        padding: 4px 0;
    }

    .about_top_boxed_text h5 {
        font-size: 15px;
    }
}

.about_top_boxed_text a {
    font-size: 16px;
    font-weight: 500;
}

.about_top_boxed_vector {
    position: absolute;
    bottom: 15px;
    right: 20px;
}

.about_area_main_text {
    /* padding-top: 54px; */
}

.about_area_heading {
    position: relative;
    display: flex;
    align-items: center;
    left: -119px;
}

.about_area_heading h3 {
    color: var(--paragraph-color);
    font-weight: 400;
}

.about_area_heading_two h2 {
    line-height: 50px;
    font-weight: 500;
}

.color_big_heading {
    position: relative;
    z-index: 1;
}

.color_big_heading::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    background: #FDC221;
    bottom: 4px;
    left: 0;
    z-index: -1;
}

.about_area_heading_two h3 {
    font-weight: 500;
    color: var(--main-color);
    padding-top: 15px;
}

.about_area_para {
    padding-top: 20px;
}

.about_area_para h5 {
    line-height: 26px;
}

.about_area_para p {
    font-size: 18px;
    padding-top: 20px;
    line-height: 26px;
}

.about_vedio_area {
    padding-top: 45px;
}

.vedio_btn {
    margin-left: 35px;
    font-size: 18px;
    font-weight: 600;
    color: var(--black-color);
}

.vedio_btn i {
    height: 50px;
    width: 50px;
    border: 1px solid;
    text-align: center;
    line-height: 46px;
    border-radius: 50%;
    font-size: 24px;
    margin-right: 14px;
    color: var(--main-color);
}

/* -- Donate Area-- */
#donate_area {
    background-image: url('./assets/img/common/donat_banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 100px 0;
}

@media screen and (max-width: 1200px) {
    #donate_area {
        background-image: url('./assets/img/common/donat_banner_mobile.jpg');
        height: 80vh;
    }
}

.donate_text {
    text-align: center;
    margin-top: -50px
}

.donate_text h3 {
    color: var(--white-color);
    font-size: 20px;
    font-weight: 400;
}

.donate_text h2 {
    /* color: var(--white-color); */
    font-size: 38px;
    line-height: 50px;
    padding-top: 20px;
    color: #3e5294;
    font-weight: 700;
    margin-bottom: 20px;
}


#partner_area {
    padding: 100px 0 200px 0;
}

#partner_area .owl-carousel .owl-item img {
    width: auto !important;
}

/* --Counter Area-- */
#counter_area {
    z-index: 9;
}

.counter_area_wrapper {
    background: #FFFFFF;
    box-shadow: -4px -6px 24px rgba(0, 0, 0, 0.08), 8px 12px 24px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 30px 20px;
    margin-top: -100px;
}

.counter_item {
    text-align: center;
    border-right: 1px solid #eeeeee;
}

.row .col-lg-3:last-child .counter_item {
    border-right: none;
}

.counter_item h2 {
    line-height: 50px;
    padding-top: 10px;
}

.counter_item p {
    font-size: 18px;
    padding-top: 10px;
}

/* --Blog area-- */
#home_blog_area {
    padding: 200px 0 100px 0;
    margin-top: -100px;
}

.blog_card_wrapper {
    background: #FFFFFF;
    box-shadow: 6px 12px 24px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    z-index: 9;
}

.blog_card_img {
    overflow: hidden;
}

.blog_card_img a img {
    width: 100%;
    transition: var(--transition);
}

.blog_card_wrapper:hover .blog_card_img a img {
    transform: scale(1.2);
}

.blog_card_text {
    padding: 20px 20px;
}

.blog_card_heading {
    padding-top: 10px;
}

.blog_card_heading h3 {
    line-height: 35px;
}

.blog_card_heading h3 a {
    color: var(--black-color);
}

.blog_card_heading h3 a:hover {
    color: var(--main-color);
}

.blog_card_heading p {
    padding-top: 13px;
}


.blog_boxed_bottom_wrapper {
    border-top: 1px solid #c4c4c457;
    padding-top: 15px;
    margin-top: 17px;
}

.blog_boxed_bottom_wrapper .row .col-lg-6:last-child .blog_bottom_boxed {
    border-right: none;
}

.blog_bottom_boxed {
    display: flex;
    align-items: center;
    border-right: 1px solid #e6e6e6;
}

.blog_bottom_content {
    padding-left: 8px;
}

.blog_bottom_content h5 {
    font-size: 16px;
    font-weight: 500;
}

.blog_bottom_content p {
    padding-top: 0;
    font-size: 14px;
}

/* --Subscribe Area-- */
.subscribe_wrapper {
    background: #FFFFFF;
    box-shadow: -4px -6px 24px rgba(0, 0, 0, 0.08), 8px 12px 24px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 40px 85px 40px 85px;
}

.subscribe_text h3 {
    font-size: 30px;
    padding-top: 10px;
    line-height: 40px;
}

#subscribe_form .form-control {
    border: 1px solid var(--main-color);
    height: 60px;
    background: var(--white-color);
}

#subscribe_form .btn_md {
    padding: 16px 35px;
    font-size: 18px;
}

/* --Footer Area-- */


.footer_area_about {
    padding: 0 20px;
}

.footer_area_about p {
    padding-top: 20px;
    padding-bottom: 13px;
}

.footer_area_about h6 {
    padding-top: 5px;
    font-size: 16px;
    color: var(--paragraph-color);
    line-height: 27px;
}

.footer_area_about h6 strong {
    color: var(--black-color);
}

.footer_area_about h6 a {
    color: var(--paragraph-color);
}

.footer_area_about h6 a:hover {
    color: var(--main-color);
}

.footer_navitem_ara h3 {
    padding-bottom: 20px;
}

.nav_item_footer ul li {
    padding-bottom: 6px;
}

.nav_item_footer ul li:last-child {
    padding-bottom: 0px;
}

.nav_item_footer ul li a {
    color: var(--black-color);
    display: block;
}

.nav_item_footer ul li a:hover {
    color: var(--main-color);
}

.footer_twitter_area p {
    padding: 10px 0;
}

.footer_twitter_area h6 {
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;
}

.icon-footer {
    transition: .3s all;
}

.icon-footer:hover {
    transform: scale(1.20);
}

/* -Copyright Area- */
.copyright_area {
    background-color: var(--main-color);
    padding: 15px 0;
}

.copyright_left p {
    color: var(--white-color);
}

.copyright_right {
    padding: 1px 30px;
}

.copyright_right ul li {
    display: inline-flex;
    margin-left: 20px;
}

.copyright_right ul li a {
    color: var(--white-color);
}


/* =========================
        About Page
=========================*/
/* --Common Banner-- */
#common_banner_area {
    background-image: url('./assets/img/common/common-banner.png');
    background-size: cover;
    background-position: center;
    padding: 145px 0;
}

.commn_banner_page h2 {
    color: #fff;
    font-size: 36px;
}

.breadcrumb_wrapper {
    display: flex;
    padding-top: 10px;
}

.breadcrumb_item {
    padding: 0 10px 0 0;
}

.breadcrumb_item a {
    color: var(--white-color);
}

.breadcrumb_item.active {
    color: #ffffff8c;
}

/* ---volunteer--- */
#volunteer_area_main .volunteer_wrapper {
    margin-bottom: 30px;
}

.volunteer_wrapper {
    z-index: 9;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 8px 10px 24px rgba(0, 0, 0, 0.1);
    transition: var(--transition);
}

.volunteer_wrapper:hover {
    box-shadow: rgb(0 0 0 / 14%) 8px 20px 22px;
}

.volunteer_img img {
    width: 100%;
}

.volunteer_img {
    position: relative;
}

.volunteer_icon {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 20px);
    transition: var(--transition);
    opacity: 0;
}

.volunteer_wrapper:hover .volunteer_icon {
    opacity: 1;
    transform: translate(-50%, 0px);
}

.volunteer_icon ul {
    display: flex;
    justify-content: center;
}

.volunteer_icon ul li {
    padding-left: 20px;
}

.volunteer_icon ul li:first-child {
    padding-left: 0px;
}

.volunteer_icon ul li a:hover {
    background: var(--main-color);
}

.volunteer_icon ul li a {
    height: 30px;
    width: 30px;
    background: var(--black-color);
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    display: block;
    transition: var(--transition);
}

.volunteer_text {
    padding: 20px 20px;
    text-align: center;
}

.volunteer_text h3 a {
    color: var(--black-color);
}

.volunteer_text h3 a:hover {
    color: var(--main-color);
}

.volunteer_text p {
    padding-top: 6px;
}

#partner_area-2 .owl-carousel .owl-item img {
    width: auto !important;
}

/* --middel Banner-- */
#newletter_banner {
    background-image: url('./assets/img/common/midel_banner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 50px 0;
}

.newletter_text h4 {
    color: var(--paragraph-color);
    font-size: 16px;
    position: relative;
    left: -99px;
    top: 0px;
}

.newletter_text h4 img {
    width: 13%;
}

.newletter_text h2 {
    font-size: 30px;
    line-height: 40px;
}

.newletter_button {
    text-align: right;
}

.news_btn {
    padding: 13px 60px;
    font-size: 18px;
}

/* --Testimonial Area-- */
.testimonial_wrapper_boxed {
    background: #FFFFFF;
    box-shadow: -5px -6px 24px rgb(0 0 0 / 2%), 8px 0px 24px rgb(0 0 0 / 9%);
    border-radius: 8px;
    padding: 10px 15px 25px 15px;
    position: relative;
    text-align: center;
    margin: 30px 10px;
}

.testimonial_wrapper_boxed img {
    width: 110px !important;
    margin: 0 auto;
}

.testimonial_wrapper_boxed p {
    padding-top: 25px;
    line-height: 28px;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 20px;
}

.test_author {
    padding-top: 20px;
}

.test_author img {
    position: absolute;
    top: 77px;
    left: 13px;
    width: auto !important;
}

.test_author h5 {
    padding-top: 5px;
}

.slider_dots.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 0px;
}

.slider_dots.owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
    margin: 5px 5px;
    background: #e03c3366;
    display: block;
    backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}

.slider_dots.owl-theme .owl-dots .owl-dot.active span,
.slider_dots.owl-theme .owl-dots .owl-dot:hover span {
    background: var(--main-color);
}

#trending_causes_main .case_boxed_wrapper {
    margin-bottom: 30px;
}

/* =========================
    Causes Details Page
=========================*/
.details_big_img {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.details_big_img img {
    width: 100%;
    transition: var(--transition);
}

.details_big_img img:hover {
    transform: scale(1.3);
}

.causes_pro_bar_flex {
    background: #EEF4F8;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
    padding: 40px 20px;
    border-radius: 10px;
    margin-top: 30px;
    z-index: 999;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.causes_pro_bar_flex .class-full-bar-box {
    position: relative;
    width: 70%;
}

.details_text_wrapper {
    padding-top: 30px;
}

.details_text_wrapper p {
    padding-top: 15px;
}

.details_text_wrapper h3 {
    padding-top: 15px;
}

.details_small_img {
    padding-top: 30px;
}

.details_small_img img {
    width: 100%;
}

.details_text_wrapper ul {
    padding-top: 10px;
}

.details_text_wrapper ul li {
    padding-top: 15px;
    color: var(--paragraph-color);
}

.details_text_wrapper ul li i {
    font-size: 9px;
    color: var(--main-color);
    padding-right: 10px;
}

.download_pdf_area {
    background: #EEF4F8;
    padding: 25px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    border-radius: 8px;
}

.pdf_download_left {
    display: flex;
    align-items: center;
}

.pdf_download_left h4 {
    padding-left: 20px;
}

.comment_area_details {
    padding-top: 80px;
}

.post_comment_item {
    display: flex;
    padding-top: 30px;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 30px;
}

.post_comment_item:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.post_comment_img {
    width: 15%;
}

.post_comment_text {
    padding-left: 20px;
}

.post_names_replay {
    display: flex;
    justify-content: space-between;
}

.post_names_replay h5 {
    font-weight: 500;
}

.post_comment_text p {
    padding-top: 10px;
}

.post_names_replay a i {
    padding-right: 5px;
}

.comment_form_area {
    padding-top: 60px;
}

#comment_form .form-control {
    margin-bottom: 30px;
}

textarea.form-control {
    height: auto;
}

.comment_form_area h3 {
    padding-bottom: 30px;
}

/* --sidebar-- */
.project_organizer_wrapper {
    display: flex;
}

.sidebar_boxed {
    background: #FFFFFF;
    box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.08), 8px 10px 24px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 30px 30px;
    margin-bottom: 30px;
}

.project_organizer_text {
    padding-left: 25px;
}

.project_organizer_text h5 {
    line-height: 13px;
    padding-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
}


.project_organizer_text p {
    padding-top: 5px;
}

.project_organizer_text ul li {
    padding-top: 10px;
    color: var(--paragraph-color);
    font-weight: 500;
}

.project_organizer_text ul li img {
    padding-right: 5px;
    width: 36px;
}

.project_organizer_text ul li span {
    color: var(--black-color);
}

.recent_donet_item {
    display: flex;
    padding-top: 25px;
}

.recent_donet_img {
    width: 25%;
}

.recent_donet_text {
    width: 100%;
    padding-left: 15px;
}

.sidebar_inner_heading {
    display: flex;
    justify-content: space-between;
}

.sidebar_inner_heading h4 {
    font-size: 18px;
    font-weight: 600;
}

.sidebar_inner_heading h4 a {
    color: var(--black-color);
}

.sidebar_inner_heading h4 a:hover {
    color: var(--main-color);
}

.sidebar_inner_heading h5 {
    font-size: 18px;
    color: var(--main-color);
    font-weight: 600;
}

.recent_donet_text p {
    padding-top: 5px;
}

.recent_donet_text h6 {
    padding-top: 5px;
    font-weight: 500;
}

.sidebar_heading_main {
    border-bottom: 1px solid #ededed;
    padding-bottom: 7px;
}

.recent_causes_wrapper .sidebar_inner_heading h4 a {
    line-height: 26px;
}

.recent_causes_wrapper .recent_donet_text h6 {
    padding-top: 10px;
    font-weight: 500;
}

.social_icon_sidebar ul li {
    display: inline-flex;
    padding-top: 20px;
    padding-right: 15px;
    transition: var(--transition);
}

.social_icon_sidebar ul li:hover {
    transform: translateY(-5px);
}

.social_icon_sidebar ul li a img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.tags_noted {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px;
    display: block;
}


.event_details_list ul li {
    padding-top: 20px;
}

.event_details_list ul li {
    padding-top: 20px;
    color: var(--paragraph-color);
    font-weight: 500;
}

.event_details_list ul li img {
    padding-right: 5px;
    width: 36px;
}

.event_details_list ul li span {
    color: var(--black-color);
}

.register_now_details {
    padding-top: 40px;
}

/* ----------------------------
        News Page
---------------------------------*/
.blog_two_wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.blog_two_wrapper img {
    width: 100%;
    transition: var(--transition);
}

.blog_two_wrapper:hover img {
    transform: scale(1.2);
}

.news_two_text {
    position: absolute;
    bottom: 0;
    padding: 30px 20px;
}

.news_two_text h3 a {
    color: var(--white-color);
    padding-top: 11px;
    display: block;
}

.news_two_text h3 a:hover {
    color: var(--main-color);
}

.popular_tags ul {
    padding-top: 10px;
}

.popular_tags ul li {
    display: inline-flex;
    background: #dddddd70;
    padding: 6px 10px;
    margin-right: 10px;
    margin-top: 15px;
}

.popular_tags li a {
    color: var(--black-color);
}

.error_area_content a {
    margin-top: 40px;

}

/* ----------------------------
        login Page
---------------------------------*/
.author_form_area {
    background: #FFFFFF;
    box-shadow: -4px -6px 24px rgba(0, 0, 0, 0.08), 8px 12px 24px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 40px 40px;
}

#author_form .form-control {
    margin-top: 15px;
}

.author_submit_form {
    padding-top: 40px;
    text-align: center;
}

.author_submit_form p {
    padding-top: 20px;
}

/* ----------------------------
        contact Page
---------------------------------*/
.contact_left_item {
    background: #FFFFFF;
    box-shadow: 8px 10px 24px rgb(0 0 0 / 10%);
    border-radius: 8px;
    display: flex;
    padding: 30px 40px;
    margin-bottom: 30px;
}

.contact_left_text {
    padding-left: 20px;
}

.contact_left_text h3 {
    padding-bottom: 10px;
}

.contact_left_text p {
    font-size: 18px;
    line-height: 28px;
}

.contact_left_text a {
    color: var(--paragraph-color);
    display: block;
    font-size: 18px;
    padding-top: 5px;
}

.contact_left_text a:hover {
    color: var(--main-color);
}

.contact_form_Wrapper {
    background: #FFFFFF;
    box-shadow: 8px 10px 24px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 20px 40px;
}

.contact_form_Wrapper h3 {
    padding-bottom: 20px;
}

#contact_form .form-group {
    padding-bottom: 15px;
}

.contact_map_area {
    margin-top: -115px;
}

/* ----------------------------
        Faqs Page
---------------------------------*/
#faqs_arae_main .about_top_boxed {
    margin-top: 40px;
}

#faqs_arae_main .accordion-item .accordion-button {
    padding: 5px 15px;
}

#faqs_arae_main .accordion-button:not(.collapsed) {
    color: #fff;
    background-color: var(--main-color);
}

.accordion-button:focus {
    z-index: 3;
    border-color: none;
    outline: 0;
    box-shadow: none;
}

/* ----------------------------
    Make a donetion Page
---------------------------------*/
.sidebar_first .case_boxed_wrapper {
    margin-bottom: 30px;
}

.donet_amount_area {
    background: #FFFFFF;
    box-shadow: -4px -4px 20px rgb(0 0 0 / 8%), 8px 10px 24px rgb(0 0 0 / 8%);
    border-radius: 6px;
    padding: 55px 25px;
    display: flex;
    justify-content: space-between;
}

.input_donet_amount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.input_donet_amount span {
    font-size: 30px;
}

.input_donet_amount input {
    border: none;
    border-bottom: 1px solid var(--main-color);
    font-size: 24px;
    width: 55%;
}

.input_donet_amount input:focus {
    border-bottom: 1px solid var(--main-color);
    outline: none;
}

.donet_amount_form_area {
    background: #FFFFFF;
    box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.08), 8px 10px 24px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 25px 25px;
}


.donet_amount_form_area .form-group {
    margin-top: 30px;
}

.doner_content_pbottom {
    margin-bottom: 30px;
}

.payment_option_area {
    padding-top: 30px;
}

.payment_option_area ul {
    display: flex;
}

.payment_option_area ul li {
    padding-right: 25px;
}

.payment_option_area ul li img {
    cursor: pointer;
}

.payment_amount_submit {
    padding-top: 30px;
}

/* ----------------------------
    Gallery Page
---------------------------------*/
.gallery_item {
    position: relative;
    margin-bottom: 30px;
}

.gallery_item img {
    /* width: 100%; */
}

.gallery_overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: #ff00003b;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition);
}

.gallery_item:hover .gallery_overlay {
    opacity: 1;
    visibility: visible;
}

.gallery_overlay p.a img {
    width: auto;
    cursor: pointer;
}

/* ----------------------------
    Terms of service Page
---------------------------------*/

.terms_item:first-child {
    padding-top: 0px;
}

.terms_item {
    padding-top: 30px;
}

.terms_item h4 {
    font-weight: 700;
}

.terms_item p {
    padding-top: 15px;
}


/* ----------------------------
    Preloader
---------------------------------*/

.preloader {
    position: fixed;
    z-index: 999999;
    background-color: var(--white-color);
    width: 100%;
    height: 100%;
    text-align: center;
    left: 0;
    right: 0
}

.preloader .lds-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px
}

.preloader .lds-spinner div {
    -webkit-transform-origin: 40px 40px;
    transform-origin: 40px 40px;
    -webkit-animation: lds-spinner 1.2s linear infinite;
    animation: lds-spinner 1.2s linear infinite
}

.preloader .lds-spinner div::after {
    content: " ";
    display: block;
    position: absolute;
    top: 5px;
    left: 35px;
    width: 5px;
    height: 20px;
    border-radius: 20%;
    background: var(--main-color)
}

.preloader .lds-spinner div:nth-child(1) {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s
}

.preloader .lds-spinner div:nth-child(2) {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    -webkit-animation-delay: -1s;
    animation-delay: -1s
}

.preloader .lds-spinner div:nth-child(3) {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-animation-delay: -.9s;
    animation-delay: -.9s
}

.preloader .lds-spinner div:nth-child(4) {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation-delay: -.8s;
    animation-delay: -.8s
}

.preloader .lds-spinner div:nth-child(5) {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
    -webkit-animation-delay: -.7s;
    animation-delay: -.7s
}

.preloader .lds-spinner div:nth-child(6) {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
    -webkit-animation-delay: -.6s;
    animation-delay: -.6s
}

.preloader .lds-spinner div:nth-child(7) {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-delay: -.5s;
    animation-delay: -.5s
}

.preloader .lds-spinner div:nth-child(8) {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg);
    -webkit-animation-delay: -.4s;
    animation-delay: -.4s
}

.preloader .lds-spinner div:nth-child(9) {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
    -webkit-animation-delay: -.3s;
    animation-delay: -.3s
}

.preloader .lds-spinner div:nth-child(10) {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    -webkit-animation-delay: -.2s;
    animation-delay: -.2s
}

.preloader .lds-spinner div:nth-child(11) {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg);
    -webkit-animation-delay: -.1s;
    animation-delay: -.1s
}

.preloader .lds-spinner div:nth-child(12) {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
    -webkit-animation-delay: 0s;
    animation-delay: 0s
}

@-webkit-keyframes lds-spinner {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes lds-spinner {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    vertical-align: middle;
}



/* ----------------------------
     Top To Bottom 
---------------------------------*/
.go-top {
    position: fixed;
    cursor: pointer;
    top: 0;
    right: 15px;
    color: #fff;
    background-color: var(--main-color);
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .9s;
    transition: .9s;
    border-radius: 50%
}

.go-top.active {
    top: 98%;
    -webkit-transform: translateY(-98%);
    transform: translateY(-98%);
    opacity: 1;
    visibility: visible
}

.go-top i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: .6s;
    transition: .6s
}

.go-top i:last-child {
    opacity: 0;
    visibility: hidden;
    top: 60%
}

.go-top::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #393953;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .6s;
    transition: .6s;
    border-radius: 50%
}

.go-top:hover,
.go-top:focus {
    color: #fff
}

.go-top:hover::before,
.go-top:focus::before {
    opacity: 1;
    visibility: visible
}

.go-top:hover i:first-child,
.go-top:focus i:first-child {
    opacity: 0;
    top: 0;
    visibility: hidden
}

.go-top:hover i:last-child,
.go-top:focus i:last-child {
    opacity: 1;
    visibility: visible;
    top: 50%
}

@media screen and (min-width: 1200px) {
    .col-lg-2-5 {
        flex: 0 0 auto;
        width: calc(99% / 5)
    }
}


/* (min-width: 992px)
.col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
} */

.projects-type .case_boxed_img {
    width: 100%;
    object-fit: contain;
}

.gallery .form-select {
    width: auto !important;
}

@media screen and (max-width: 992px) {

    .gallery .form-select {
        width: 100% !important;
    }

}


.full-screen {
    position: fixed;
    top: 60%;
    left: 60%;
    transform: translate(-60%, -60%);
    width: 80%;
    height: 80%;
    z-index: 1;
}

.dad_img {
    /* position: relative; */
}

.btns_Modal {
    display: flex;
}

.closeModal {
    position: absolute;
    top: 2%;
    right: 10%;
    border-radius: 30px;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .closeModal {
        top: 15%;
    }
}

/* .modal-overlay-fully {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
} */

/* .modal {
    display: block;
    position: fixed;
    top: 50%;
    left: -50%;
    z-index: 355;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
} */

.modal-content-move {
    transition: transform 0.3s ease-in-out;
}

.dad_img img {
    width: 200px;
    height: 200px;
    margin: 10px;
    /* cursor: pointer; */
}

.dad_img .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dad_img .modal-content {
    background-color: white;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1200px) {
    .modal-content-move {
        width: 95% !important;
        height: 60% !important;
    }
}

.dad_img img {
    width: 100%;
    height: 80%;
    object-fit: contain;
}

.dad_img .arrows {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.dad_img .btns_Modal button {
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    background-color: lightgray;
    border-radius: 5px;
    margin: 0 10px;
}

.dad_img .close {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: red;
    color: white;
}

/* Start Login Form */

.login-form {
    width: 45%;
    margin: 40px auto;
}

.login-form .nav-pills {
    padding: 0 30px;
}

@media screen and (max-width: 1000px) {
    .login-form {
        width: 90%;
    }
}


.Auth-form {
    width: 60%;
    margin: 40px auto;
}

.notfound {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 100;
    top: 0;
}


/* Profile User */

.img-account-profile {
    height: 10rem;
    width: 10rem;
    object-fit: cover;
}

.rounded-circle {
    border-radius: 50% !important;
}

.card {
    box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);
}

.card .card-header {
    font-weight: 500;
}

.card-header:first-child {
    border-radius: 0.35rem 0.35rem 0 0;
}

.card-header {
    padding: 1rem 1.35rem;
    margin-bottom: 0;
    background-color: rgba(33, 40, 50, 0.03);
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}

.form-control,
.dataTable-input {
    display: block;
    width: 100%;
    padding: 0.875rem 1.125rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: #69707a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c5ccd6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nav-borders .nav-link.active {
    color: #0061f2;
    border-bottom-color: #0061f2;
}

.nav-borders .nav-link {
    color: #69707a;
    border-bottom-width: 0.125rem;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}


.edit-profile {
    width: 45%;
}

.card-profile-edit {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 30px;
}

/* d-flex justify-content-between */

@media screen and (max-width: 992px) {
    .edit-profile {
        width: 100%;
    }

    .card-profile-edit {}
}

.form-payment {
    width: 45%;
    margin: 10px auto;
}


.support {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: 60px auto;
}

.support .supportBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.donate-btn {
    display: flex;
    width: 40%;
    flex-direction: column;
    justify-content: center;
    padding: 26px;
    /* border: 1px solid #123; */
    border: 1px solid #a9b4d9;
    border-radius: 16px;
}

.support_side {
    width: 48%;
}

.donate-btn input:focus {
    border: 1px solid #a9b4d9;
    outline: #4e7bbd 1px solid;
}

@media screen and (max-width: 1200px) {
    .support {
        width: 90%;
    }

    .support .supportBottom {
        flex-direction: column;
    }

    .support_side {
        width: 90%;
    }

    .donate-btn {
        width: 85%;
        margin-top: 30px;
        /* width: 55%; */
    }
}

.donate-btn button {
    border-radius: 25px;
    font-size: 18px;
}

.dropdown_support {
    margin: 16px 0 28px;
    font-weight: 500;
}

.donate-btn input {
    font-weight: 500;
}

.donate-btn input::placeholder {
    color: #333;
}


.donate-btn input {
    font-size: 16px;
    border-radius: 12px;
    border: 1px solid #25325e38;
    padding: 10px 30px;
    margin: 10px 0px 20px;
}

@media screen and (max-width: 992px) {
    .donate-btn {
        width: 90%;
        padding: 30px;
    }

    .donate-btn input {
        width: 100%;
    }
}


.text-error {
    color: red;
    padding-top: 10px;
}


.donation-confirmation {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    margin: 100px auto;
    max-width: 620px;
    padding: 60px;
    text-align: center;
}

.donation-confirmation h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.donation-confirmation p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 30px;
}


.aboutUs {
    width: 70%;
    margin: 60px auto;
}

.aboutUs .aboutSection {}

.aboutUs .aboutSection p {
    word-wrap: break-word;
}

.cursor_pointer_moh {
    cursor: pointer;
}

.loading-span {
    display: inline-block;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #575757;
    border-top-color: #b4b4b4;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}


.main-heading {
    text-align: center;
    font-weight: normal;
    font-size: 42px;
    position: relative;
    margin-bottom: 65px;
    margin-top: 20px;
    text-transform: uppercase;
}

.main-heading::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    background-color: #333;
    bottom: -30px;
    width: 120px;
}

.main-heading::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #333;
    bottom: -36px;
    background-color: white;
}

.logo-header {
    width: 80px;
    height: 80px;
    object-fit: contain;
}