#home_one_banner {
  background-image: url('../assets/img/banner/home-banner-bg.png');
  padding: 60px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner_one_img {
  position: relative;
  text-align: right;
}

.banner_one_text h1 span {
  font-weight: 700;
}

.banner_one_text .color_big {
  position: relative;
  z-index: 2;
}

.banner_one_text .color_big::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 20px;
  background: #FDC221;
  bottom: 21px;
  left: 0;
  z-index: -1;
}

.banner_one_text p {
  padding-top: 20px;
  font-size: 24px;
  line-height: 30px;
}

.banner_element img {
  position: absolute;
  box-shadow: 10px 12px 30px rgba(0, 0, 0, 0.1);
}

.element_1 {
  top: 44%;
  left: 64px;
}

.element_2 {
  top: 23%;
  right: -14%;
}

.element_3 {
  bottom: 0;
  left: 67%;
}

.shape-1 {
  -webkit-animation: left1right 5s linear 0s infinite;
  animation: left1right 5s linear 0s infinite;
}

@keyframes left1right {

  50% {
    -ms-transform: translateY(20px);
    /* IE 9 */
    -webkit-transform: translateY(20px);
    /* Chrome, Safari, Opera */
    transform: translateY(20px);
  }
}

.shape-2 {
  -webkit-animation: left2right 3s linear 0s infinite;
  animation: left2right 3s linear 0s infinite;
}

.bg-shap-header {
  background: url('../assets/img/banner/element-2.jpg');
  position: absolute;
  width: 234px;
  height: 107px;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-shap-header2 {
  background: url('../assets/img/banner/element-3.jpg');
  position: absolute;
  width: 234px;
  height: 107px;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-shap-header-text {
  width: 100%;
  text-align: center;
  display: block;
  padding-top: 20px;
}

@keyframes left2right {

  50% {
    -ms-transform: translateY(20px);
    /* IE 9 */
    -webkit-transform: translateY(20px);
    /* Chrome, Safari, Opera */
    transform: translateY(20px);
  }
}

.shape-3 {
  -webkit-animation: left13right 9s linear 0s infinite;
  animation: left3right 9s linear 0s infinite;
}

@keyframes left3right {

  50% {
    -ms-transform: translateY(20px);
    /* IE 9 */
    -webkit-transform: translateY(20px);
    /* Chrome, Safari, Opera */
    transform: translateY(20px);
  }
}




/* start responsive */

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1440px;
  }

}

@media (max-width: 1600px) {
  .element_2 {
    top: 23%;
    right: 6%;
  }

  .element_3 {
    bottom: 0;
    left: 46%;
  }
}

@media (max-width:1440px) {
  .banner_one_text {
    padding-top: 105px;
  }

  .about_top_boxed_text h3 {
    font-size: 26px;
  }

  .counter_item h2 {
    line-height: 26px;
    padding-top: 16px;
    font-size: 30px;
  }

  .main-navbar .navbar ul {
    padding-left: 20px;
  }
}

@media (max-width: 1199px) {

  .about_top_boxed {
    margin-bottom: 80px;
  }


  .post_comment_item {
    display: inherit;
  }

  .post_comment_text {
    padding-left: 0;
    padding-top: 15px;
  }

  .banner_one_text h1 {
    font-size: 60px;
    font-weight: 400;
    line-height: 70px;
  }
  .banner_one_text h3 {
    font-size: 50px !important;
    font-weight: 400;
    line-height: 50px;
  }

  .post_comment_img {
    width: auto;
  }
}

.case_boxed_wrapper {
  padding-bottom: 20px;
}

@media (max-width: 992px) {
  .causes_pro_bar_flex {
    display: inherit;
  }

  .causes_pro_bar_flex .class-full-bar-box {
    position: relative;
    width: 100%;
  }

  .details_top_btn {
    padding-top: 30px;
  }

  .comment_form_area {
    padding-bottom: 30px;
  }

  .details_text_wrapper h2 {
    font-size: 30px;
    line-height: 38px;
  }

  .newletter_button {
    text-align: left;
    padding-top: 30px;
  }

  #common_banner_area {
    padding: 85px 0;
  }

  .banner_one_img {
    /* display: none; */
  }

  .banner_one_text {
    padding-top: 100px;
    padding-bottom: 50px;
    text-align: center;
  }

  .case_boxed_wrapper {
    margin-bottom: 30px;
  }

  .event_left_side_wrapper {
    margin-bottom: 30px;
  }

  .blog_card_wrapper {
    margin-bottom: 30px;
  }

  .blog_two_wrapper {
    margin-bottom: 30px;
  }

  .cta_right_side {
    padding-top: 30px;
  }

  .volunteer_wrapper {
    margin-bottom: 30px;
  }

  .footer_navitem_ara h3 {
    padding-bottom: 15px;
  }

  .footer_navitem_ara {
    padding-top: 30px;
  }

  .contact_map_area {
    margin-top: 0;
  }

  .payment_amount_submit {
    padding-bottom: 30px;
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .download_pdf_area {
    display: inherit;
  }

  .pdf_download_right {
    padding-top: 30px;
  }

  .topbar-list-right {
    text-align: center;
  }

  #about_top_area .row .col-lg-3:last-child .about_top_boxed {
    margin-bottom: 0px;
  }

  .footer_navitem_ara {
    text-align: center;
    padding: 20px 7px 0;
  }

  .newletter_text h4 {
    left: 0px;
  }

  .newletter_text h4 img {
    display: none;
  }
}

@media (max-width: 576px) {
  .section_heading h2 {
    padding-top: 10px;
    font-size: 30px;
    line-height: 41px;
  }

  .section_heading h3 {
    font-size: 18px;
  }

  .section_heading img {
    position: absolute;
    left: 0;
    top: -23px;
  }

  .about_area_heading img {
    display: none;
  }

  .about_area_heading {
    left: 0;
  }

  .banner_one_text h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
  }
  .banner_one_text h3 {
    font-size: 28px !important;
    font-weight: 400;
    line-height: 46px;
  }

  .counter_item {
    padding-bottom: 30px;
    border-right: none;
  }

  .subscribe_wrapper {
    padding: 40px 40px 40px 40px;
  }
}

@media (max-width: 480px) {
  .project_organizer_wrapper {
    display: inherit;
  }

  .project_organizer_text {
    padding-left: 0;
    padding-top: 22px;
  }
}

@media (max-width: 361px) {}

.icon-mohammed {
  font-size: 35px;
  margin: 0 10px;
}


.p-project {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1.55;
  color: #464646
}

#gallery_grid_area .row.d-flex.align-items-center.justify-content-between {}

@media screen and (max-width: 992px) {
  #gallery_grid_area .row.d-flex.align-items-center.justify-content-between {
    width: 95%;
    margin: auto
  }

  #gallery_grid_area .popup-gallery.py-5 {
    width: 95%;
    margin: auto
  }
}